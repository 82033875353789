import React, { useContext } from 'react';

import { FormContext } from './context';

import loadComponents from '../Loadable';

const Alert = loadComponents('alert');

export default function FormAlerts() {
  const { processing, progress, sent, error } = useContext(FormContext);

  const style = { marginBottom: 30 };

  if (error) {
    return (
      <Alert error style={style}>
        {error}
      </Alert>
    );
  }

  if (sent) {
    return (
      <Alert success style={style}>
        {sent}
      </Alert>
    );
  }

  if (processing && progress > 0) {
    return (
      <Alert info style={style}>{`Submission progress ${Number(
        progress,
      ).toFixed(0)}%`}</Alert>
    );
  }

  return null;
}
